.latm3poc-grid-container {
  display: grid;
  grid-template-columns: 160px 35px 65px 50px 75px 75px 55px;
  column-gap: 10px;
  row-gap: 5px;
}
.latm3poc-span2 {
  grid-column: span 2;
}
.latm3poc-span3 {
  grid-column: span 3;
}
.latm3poc-span5 {
  grid-column: span 5;
}
.latm3poc-span7 {
  grid-column: span 7;
}
.latm3poc-grid-container > div {
  /* background-color: rgba(255, 255, 255, 0.8); */
  text-align: left;
}

.latm3pocLabelText {
  font-size: 10pt;
  font-family: '"Arial", sans-serif';
}

.latm3pocDisabled {
  pointer-events: none;
  opacity: 0.3;
}

.latm3pocTextInput {
  font-size: 8pt;
  font-family: '"Arial", sans-serif';
}

.latm3pocFieldError {
  background-color: #ff6060 !important;
}
.latm3pocFieldError:-webkit-autofill {
  background-color: #ff6060 !important;
}
.latm3pocFieldFocus {
  border-bottom: linear-gradient(270deg, #4d80e6 0%, #e12128 100%);
  border-image: linear-gradient(270deg, #4d80e6 0%, #e12128 100%);
  border-image-slice: 1;
}
.latm3pocFieldFocus:-webkit-autofill {
  border-bottom: linear-gradient(270deg, #4d80e6 0%, #e12128 100%);
  border-image: linear-gradient(270deg, #4d80e6 0%, #e12128 100%);
  border-image-slice: 1;
}

.latm3pocMarginLeft {
  margin-left: 10px;
}

.latm3pocMarginButton {
  margin-left: 5px;
  padding: 0px;
  margin-right: 0px;
  margin-top: 0px;
  height: 18px;
}

.latm3pocTable {
  border-collapse: collapse;
  font-size: 10pt;
  font-family: '"Arial", sans-serif';
}
.latm3pocTable thead tr th {
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
}
.latm3pocTable th,
.latm3pocTable td {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.latm3pocTable th,
.latm3pocTable td {
  /* Added padding for better layout after collapsing */
  padding: 4px 8px;
}
.latm3pocTableHeadPad {
  margin-top: 5px;
}

.flex-microform {
  height: 20px;
  background: #ffffff;
  -webkit-transition: background 200ms;
  transition: background 200ms;
}
#securityCode-container.flex-microform {
  background: #ffffff;
}

.flex-microform-focused {
  background: lightyellow;
}

.flex-microform-valid {
  background: lightyellow;
}
