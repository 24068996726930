/* Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
--------------------------------------------------------------------------------------- */
.c360b2bcarepak html,
.c360b2bcarepak body,
.c360b2bcarepak span,
.c360b2bcarepak applet,
.c360b2bcarepak object,
.c360b2bcarepak iframe,
/* .c360b2bcarepak h1, */
.c360b2bcarepak h2,
.c360b2bcarepak h3,
.c360b2bcarepak h4,
.c360b2bcarepak h5,
.c360b2bcarepak h6,
.c360b2bcarepak p,
.c360b2bcarepak blockquote,
.c360b2bcarepak pre,
.c360b2bcarepak a,
.c360b2bcarepak abbr,
.c360b2bcarepak acronym,
.c360b2bcarepak address,
.c360b2bcarepak big,
.c360b2bcarepak cite,
.c360b2bcarepak code,
.c360b2bcarepak del,
.c360b2bcarepak dfn,
.c360b2bcarepak em,
.c360b2bcarepak img,
.c360b2bcarepak ins,
.c360b2bcarepak kbd,
.c360b2bcarepak q,
.c360b2bcarepak s,
.c360b2bcarepak samp,
.c360b2bcarepak small,
.c360b2bcarepak strike,
.c360b2bcarepak strong,
.c360b2bcarepak sub,
.c360b2bcarepak sup,
.c360b2bcarepak tt,
.c360b2bcarepak var,
.c360b2bcarepak b,
.c360b2bcarepak u,
.c360b2bcarepak i,
.c360b2bcarepak center,
.c360b2bcarepak dl,
.c360b2bcarepak dt,
.c360b2bcarepak dd,
.c360b2bcarepak ol,
.c360b2bcarepak ul,
.c360b2bcarepak li,
.c360b2bcarepak fieldset,
.c360b2bcarepak form,
.c360b2bcarepak label,
.c360b2bcarepak legend,
.c360b2bcarepak table,
.c360b2bcarepak caption,
.c360b2bcarepak tbody,
.c360b2bcarepak tfoot,
.c360b2bcarepak thead,
.c360b2bcarepak tr,
.c360b2bcarepak th,
.c360b2bcarepak td,
.c360b2bcarepak article,
.c360b2bcarepak aside,
.c360b2bcarepak canvas,
.c360b2bcarepak details,
.c360b2bcarepak embed,
.c360b2bcarepak figure,
.c360b2bcarepak figcaption,
.c360b2bcarepak footer,
.c360b2bcarepak header,
.c360b2bcarepak hgroup,
.c360b2bcarepak menu,
.c360b2bcarepak nav,
.c360b2bcarepak output,
.c360b2bcarepak ruby,
.c360b2bcarepak section,
.c360b2bcarepak summary,
.c360b2bcarepak time,
.c360b2bcarepak mark,
.c360b2bcarepak audio,
.c360b2bcarepak video {
  margin: 0;
  padding: 0;
  /* border: 1; */
  /* font-size: 100%; */
  /* font: inherit; */
  vertical-align: baseline;
}

.c360b2bcarepak div {
  margin: 0;
  padding: 0;
  border: 2;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.c360b2bcarepak article,
.c360b2bcarepak aside,
.c360b2bcarepak details,
.c360b2bcarepak figcaption,
.c360b2bcarepak figure,
.c360b2bcarepak footer,
.c360b2bcarepak header,
.c360b2bcarepak hgroup,
.c360b2bcarepak menu,
.c360b2bcarepak nav,
.c360b2bcarepak section {
  display: block;
}

.c360b2bcarepak body {
  line-height: 1;
}

.c360b2bcarepak ol,
.c360b2bcarepak ul {
  list-style: none;
}

.c360b2bcarepak blockquote,
.c360b2bcarepak q {
  quotes: none;
}

.c360b2bcarepak blockquote:before,
.c360b2bcarepak blockquote:after,
.c360b2bcarepak q:before,
.c360b2bcarepak q:after {
  content: "";
  content: none;
}

.c360b2bcarepak table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/2A0298_6_0.eot");
  src: url("./fonts/2A0298_6_0.eot?#iefix") format("embedded-opentype"), url("./fonts/2A0298_6_0.woff") format("woff"),
    url("./fonts/2A0298_6_0.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/2A0298_7_0.eot");
  src: url("./fonts/2A0298_7_0.eot?#iefix") format("embedded-opentype"), url("./fonts/2A0298_7_0.woff") format("woff"),
    url("./fonts/2A0298_7_0.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/2A0298_8_0.eot");
  src: url("./fonts/2A0298_8_0.eot?#iefix") format("embedded-opentype"), url("./fonts/2A0298_8_0.woff") format("woff"),
    url("./fonts/2A0298_8_0.ttf") format("truetype");
  font-weight: 700;
}

.c360b2bcarepak html,
.c360b2bcarepak body {
  background: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.c360b2bcarepak body:not(.focus--mouse) *:focus {
  box-shadow: none;
}

.c360b2bcarepak *,
.c360b2bcarepak *:after,
.c360b2bcarepak *:before {
  box-sizing: border-box;
  outline: none;
}

/* .c360b2bcarepak h1, */
.c360b2bcarepak h2,
.c360b2bcarepak h3,
.c360b2bcarepak h4,
.c360b2bcarepak h5,
.c360b2bcarepak h6,
.c360b2bcarepak p,
.c360b2bcarepak input,
.c360b2bcarepak textarea,
.c360b2bcarepak select {
  color: #171719;
  font-family: "Proxima Nova";
  font-weight: normal;
}

.c360b2bcarepak h2 {
  font-size: 18px;
}

.c360b2bcarepak h3 {
  font-size: 16px;
  font-weight: 600;
}

.c360b2bcarepak h4 {
  font-size: 14px;
}

.c360b2bcarepak h5 {
  font-size: 13px;
}

.c360b2bcarepak h6 {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  font-weight: 600;
}

.c360b2bcarepak p,
.c360b2bcarepak input,
.c360b2bcarepak textarea {
  font-size: 14px;
  line-height: 1.5;
  color: #666666;
}

.c360b2bcarepak a,
.c360b2bcarepak a:hover {
  color: #cc0000;
  text-decoration: none;
}

.c360b2bcarepak img {
  display: block;
  max-width: 100%;
}

.c360b2bcarepak button {
  background: none;
  border: none;
  cursor: pointer;
}

.c360b2bcarepak textarea,
.c360b2bcarepak input {
  border: none;
  padding: 0px;
  background-color: rgba(255, 255, 255, 0);
  min-width: 0px;
  width: 100%;
  resize: none;
  font-size: 12px;
}

.c360b2bcarepak label,
.c360b2bcarepak small {
  color: #171719;
  font-family: "Proxima Nova";
  font-weight: normal;
  color: #666666;
  font-size: 12px;
}

.c360b2bcarepak b,
.c360b2bcarepak strong {
  font-weight: 600;
  color: #171719;
}

.c360b2bcarepak .CardButton__CardButton_smcal {
  /* width: 75px;
    height: 68px;
    padding: 10px; */
  margin: 1px 1px 10px 0px; /*top right bottom left */
  border: 0px solid #d6dbe2;
  background: #ffffff;
  /* width: 50%; */
  height: 40%;
  /* border-radius: 4px; */
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: left;
  justify-content: center;
  text-align: center;
  /*cursor: pointer;*/
}

.c360b2bcarepak .custom_overlay .CardButton__CardButton_sm input[type="radio"] {
  margin: 0;
  padding: 0;
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}

.c360b2bcarepak .custom_overlay .CardButton__CardButton_sm label {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  /* cursor: pointer; */
}

.c360b2bcarepak .custom_overlay .CardButton__CardButton_sm [type="radio"]:checked + label {
  box-shadow: 0 0 0 1px #cc0000;
  border: 1px solid #cc0000;
}

.c360b2bcarepak .custom_overlay .CardButton__CardButton_sm {
  /* width: 50px;
    height: 45.38px; */
  position: relative;
}

.c360b2bcarepak .CardButton__CardButton_sm {
  /* width: 75px;
    height: 68px;
    padding: 10px; */
  margin: 10px 2px 12px 0px; /*top right bottom left */
  border: 2px solid #d6dbe2;
  background: #ffffff;
  /* border-radius: 4px; */
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: left;
  justify-content: center;
  text-align: center;
  /*cursor: pointer;*/
}

.c360b2bcarepak .CardButton__Group_sm > .CardButton__inner_sm {
  margin-bottom: -8px;
  margin-top: -24px;
  display: flex;
  flex-wrap: wrap;
}

.c360b2bcarepak .CardButton__Group_sm > .CardButton__inner_sm > * {
  margin-bottom: 8px;
}

.c360b2bcarepak .CardButton__Group_sm > .CardButton__inner_sm > *:last-child {
  margin-right: 0px;
}

@media only screen and (max-width: 768px) {
  .c360b2bcarepak .CardButton__Group_sm > .CardButton__inner_sm {
    justify-content: left;
  }

  .c360b2bcarepak .CardButton__Group_sm > .CardButton__inner_sm > *:nth-child(even) {
    margin-right: 3px;
  }

  .c360b2bcarepak .Popup__card {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .c360b2bcarepak .Input__Input {
    padding: 0 10px;
  }

  .c360b2bcarepak .ui_panelgrid .ui_panelgrid-even .ui_panelgrid-cell:last-child {
    padding: 4px 0;
  }

  .c360b2bcarepak tr.ui_widget-content.ui_panelgrid-odd > td:nth-child(2) > label,
  .c360b2bcarepak .ui_timer {
    width: auto;
  }

  /* .c360b2bcarepak .Label__main {
  } */

  .c360b2bcarepak .Col__Col.Col__padding_sm:last-child .Label__group {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 400px) {
  .c360b2bcarepak .Col__Col.Col__sm_12.Col__md_6v {
    display: initial;
    float: table-row;
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 5px;
  }

  .c360b2bcarepak .Col__Col.Col__marginTop {
    margin-top: 20px;
  }

  .c360b2bcarepak .Col__Col.Col__sm_12.Col__md_5v {
    display: initial;
    float: table-row;
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 5px;
  }

  .c360b2bcarepak .Col__Col.Col__sm_12.Col__md_8 {
    display: initial;
    float: table-row;
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 5px;
  }

  .c360b2bcarepak .Card__Card___37_IE {
    height: inherit;
  }

  .c360b2bcarepak .Card__Card___37_IE:not(.Card__size_lg___-6UrJ) .Card__Image___frdcM {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    min-width: 32px;
  }
}

/* .c360b2bcarepak .Col__Col.Col__padding_sm:last-child .Label__group {
  padding-left: 15px;
} */

.c360b2bcarepak .Col__Col.Col__padding_sm .Label__group {
  width: 100%;
  padding-left: 0px;
}

.c360b2bcarepak .Col__Col.Col__padding_sm {
  padding: 0px 0px;
}

.c360b2bcarepak .Col__Col.Col__padding_sm_10Left {
  padding-left: 15px;
}

.c360b2bcarepak .Col__Col.top_level_submitsm {
  display: -webkit-flex;
  /* Safari */
  -webkit-flex-direction: center;
  /* Safari 6.1+ */
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0px auto;
  align-items: center;
  align-self: center;
}

.c360b2bcarepak .Col__Col.Col__md_6 {
  display: initial;
  flex-basis: 50%;
  max-width: 50%;
  vertical-align: center;
}

.c360b2bcarepak .Col__Col.Col__md_1 {
  display: initial;
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

.c360b2bcarepak .Col__Col.Col__md_1v {
  display: initial;
  flex-basis: 2.33333333%;
  max-width: 2.33333333%;
}

.c360b2bcarepak .Col__Col.Col__md_2 {
  display: initial;
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.c360b2bcarepak .Col__Col.Col__md_3 {
  display: initial;
  flex-basis: 25%;
  max-width: 25%;
}

.c360b2bcarepak .Col__Col.Col__md_4 {
  display: initial;
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.c360b2bcarepak .Col__Col.Col__md_5 {
  display: initial;
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}

.c360b2bcarepak .Col__Col.Col__md_5v {
  display: initial;
  flex-basis: 47.66666667%;
  max-width: 47.66666667%;
}

.c360b2bcarepak .Col__Col.Col__md_6v {
  display: initial;
  flex-basis: 50%;
  max-width: 50%;
}

.c360b2bcarepak .Col__Col.Col__md_7 {
  display: initial;
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

.c360b2bcarepak .Col__Col.Col__md_8 {
  display: initial;
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

.c360b2bcarepak .Col__Col.Col__md_9 {
  display: initial;
  flex-basis: 75%;
  max-width: 75%;
}
.c360b2bcarepak .custom_overlay {
  display: flex;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(102, 102, 102, 0.9);*/
  overflow: auto;
  /*padding: 24px;*/
  overflow: hidden;
}

.c360b2bcarepak .custom_overlay .Popup__Section {
  padding-bottom: 0px;
  padding-top: 0px;
  box-shadow: none;
}

.c360b2bcarepak .custom_overlay .Form__Section {
  border: none;
}

.c360b2bcarepak .custom_overlay .CardButton__CardButton input[type="radio"] {
  margin: 0;
  padding: 0;
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}

.c360b2bcarepak .custom_overlay .CardButton__CardButton label {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  /* cursor: pointer; */
}

.c360b2bcarepak .custom_overlay .CardButton__CardButton [type="radio"]:checked + label {
  border: 1px solid #e22128;
}

.c360b2bcarepak .custom_overlay .CardButton__CardButton [type="radio"]:checked + label:after {
  content: "";
  border: 10px solid transparent;
  border-left: 10px solid #e22128;
  position: absolute;
  top: -10px;
  right: -10px;
  transform: rotate(-45deg);
}

.c360b2bcarepak .custom_overlay .CardButton__CardButton {
  width: 100px;
  height: 90.67px;
  position: relative;
}
.c360b2bcarepak .custom_overlay select {
  border: none;
}

.c360b2bcarepak .custom_overlay select.Input__Input option {
  margin: 40px;
  background: #fff;
  color: #000;
}

.c360b2bcarepak .custom_overlay .Input__Input {
  height: 50px;
  border-bottom: solid 2px #000000;
  background: #f8f8f8;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.c360b2bcarepak .disable_datepicket {
  color: gray !important;
  opacity: 0.4;
  cursor: default;
}

.c360b2bcarepak .enable_datepicket {
  color: black;
  opacity: 1;
  cursor: default;
}
.c360b2bcarepak .Form__Form:not(.Form__plain) > * {
  margin-bottom: 16px;
}

.c360b2bcarepak .Form__Form:not(.Form__plain) > *:last-child {
  margin-bottom: 0px;
}

.c360b2bcarepak h1 {
  border: 0;
  border-color: #eed3d7;
  color: #d8000c;
  display: flex;
  font-family: "'Times New Roman', Times";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none solid rgb(226, 33, 40);
  text-align: left;
  word-spacing: 0px;
  /* font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: bold; */
  padding: 10px 2px 2px 10px !important;
  padding-block-end: 10px !important;
  white-space: "break-spaces";
  vertical-align: middle;
}

.c360b2bcarepak .Input__Input.red {
  border-color: #cc0000;
}

.c360b2bcarepak .Input__Input input,
select.Input__Input {
  color: #171719;
  padding-left: 20px;
  margin-right: 20px;
  font-size: 16px;
}

.c360b2bcarepak .Input__Input:focus-within,
.c360b2bcarepak select.Input__Input:focus-within {
  border-image: linear-gradient(270deg, #4d80e6 0%, #e12128 100%);
  border-image-slice: 1;
}

.c360b2bcarepak .Input__Input input:focus,
.c360b2bcarepak select.Input__Input:focus {
  box-shadow: none;
}

.c360b2bcarepak .select.Input__Input::-ms-expand {
  display: none;
}

.c360b2bcarepak .Label__field {
  border: 0;
  color: #171719;
  font-family: "Proxima Nova";
  font-size: 18px;
  font-weight: 500;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

.c360b2bcarepak .Label__group > *:first-child {
  flex: 1;
  width: 100%;
}

.c360b2bcarepak .Label__group > *:last-child {
  margin-right: 0px;
}

.c360b2bcarepak .Label__group > * {
  margin-right: 12px;
}

.c360b2bcarepak .Label__group .wrapper {
  position: unset;
}

.c360b2bcarepak .Label__main {
  font-weight: 600;
  margin-bottom: 8px;
}

.c360b2bcarepak .Link__Link {
  color: #171719;
  font-family: "Proxima Nova";
  font-weight: normal;
  color: #666666;
  font-size: 12px;
  display: block;
  transition: 0.2s color;
  /* cursor: pointer; */
}

.c360b2bcarepak .Popup__card {
  width: 100%;
  background-color: #ffffff;
  max-height: 100%;
}

.c360b2bcarepak .Sample__container__content {
  display: flex;
  max-width: 100%;
  /* min-width: 400px; */
  flex-basis: 420px;
  flex-direction: column;
  flex-grow: 100;
  align-items: stretch;
  padding-top: 1em;
  padding-left: 1em;
}

.c360b2bcarepak .Sample__container__content .react_calendar {
  margin: 0 auto;
}

.c360b2bcarepak .react_datepicker_popper {
  border: 1px;
}
.c360b2bcarepak .Row__Row.Row__padding_sm:last-child {
  margin-bottom: 0px;
}

.c360b2bcarepak .Row__Row.Row__padding_sm {
  margin: 5px 0px 25px 0px; /*top right bottom left */
}

.c360b2bcarepak .Row__Row.Row__padding_sm_label {
  margin: 0px 0px 5px 0px; /*top right bottom left */
}

.c360b2bcarepak .Row__Row.Row__padding_smb:first-child {
  margin-left: 10px;
}

.c360b2bcarepak .Row__Row.Row__padding_smb:last-child {
  margin-bottom: 0px;
}

.c360b2bcarepak .Row__Row.Row__padding_smb {
  margin: 25px 0px; /*top-bottom right-left */
  justify-content: bottom;
}

.c360b2bcarepak .Row__Row_Left.Row__padding_smcal {
  margin: 0px 0px;
  margin-bottom: 25px;
}

.c360b2bcarepak .Row__Row.Row__padding_smcal:first-child {
  margin-left: 0px;
}

.c360b2bcarepak .Row__Row.Row__padding_smcal:last-child {
  margin-bottom: 25px 0px;
}

.c360b2bcarepak .Row__Row.Row__padding_smcal {
  margin: 25px 0px; /*top-bottom right-left */
  justify-content: bottom;
}

.c360b2bcarepak .Row__Row {
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  justify-content: center;
}

.c360b2bcarepak .Row__Row__Left {
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  justify-content: left;
}

.c360b2bcarepak .Row__Row_Left.Row__padding_sm {
  margin: 0px 0px;
}

.c360b2bcarepak .Row__Row_Left.Row__padding_smref:last-child {
  margin-bottom: -17px;
}

.c360b2bcarepak .Row__Row_Left.Row__padding_smref {
  margin: 0px 0px;
}

.c360b2bcarepak .Row__Row_Left {
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  justify-content: left;
  flex-direction: column;
}

.c360b2bcarepak .Search__SearchContainer {
  position: relative;
}

.c360b2bcarepak .Search__SearchInput_focus {
  border-bottom: linear-gradient(270deg, #4d80e6 0%, #e12128 100%);
  border-image: linear-gradient(270deg, #4d80e6 0%, #e12128 100%);
  border-image-slice: 1;
}

.c360b2bcarepak .top_level {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px auto;
  justify-content: left;
}

.c360b2bcarepak .Search__SearchInput:focus {
  border-bottom: linear-gradient(270deg, #4d80e6 0%, #e12128 100%);
  border-image: linear-gradient(270deg, #4d80e6 0%, #e12128 100%);
  border-image-slice: 1;
}
