/* Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
--------------------------------------------------------------------------------------- */
.c360b2bsnr html,
.c360b2bsnr body,
.c360b2bsnr span,
.c360b2bsnr applet,
.c360b2bsnr object,
.c360b2bsnr iframe,
/* .c360b2bsnr h1, */
.c360b2bsnr h2,
.c360b2bsnr h3,
.c360b2bsnr h4,
.c360b2bsnr h5,
.c360b2bsnr h6,
.c360b2bsnr p,
.c360b2bsnr blockquote,
.c360b2bsnr pre,
.c360b2bsnr a,
.c360b2bsnr abbr,
.c360b2bsnr acronym,
.c360b2bsnr address,
.c360b2bsnr big,
.c360b2bsnr cite,
.c360b2bsnr code,
.c360b2bsnr del,
.c360b2bsnr dfn,
.c360b2bsnr em,
.c360b2bsnr img,
.c360b2bsnr ins,
.c360b2bsnr kbd,
.c360b2bsnr q,
.c360b2bsnr s,
.c360b2bsnr samp,
.c360b2bsnr small,
.c360b2bsnr strike,
.c360b2bsnr strong,
.c360b2bsnr sub,
.c360b2bsnr sup,
.c360b2bsnr tt,
.c360b2bsnr var,
.c360b2bsnr b,
.c360b2bsnr u,
.c360b2bsnr i,
.c360b2bsnr center,
.c360b2bsnr dl,
.c360b2bsnr dt,
.c360b2bsnr dd,
.c360b2bsnr ol,
.c360b2bsnr ul,
.c360b2bsnr li,
.c360b2bsnr fieldset,
.c360b2bsnr form,
.c360b2bsnr label,
.c360b2bsnr legend,
.c360b2bsnr table,
.c360b2bsnr caption,
.c360b2bsnr tbody,
.c360b2bsnr tfoot,
.c360b2bsnr thead,
.c360b2bsnr tr,
.c360b2bsnr th,
.c360b2bsnr td,
.c360b2bsnr article,
.c360b2bsnr aside,
.c360b2bsnr canvas,
.c360b2bsnr details,
.c360b2bsnr embed,
.c360b2bsnr figure,
.c360b2bsnr figcaption,
.c360b2bsnr footer,
.c360b2bsnr header,
.c360b2bsnr hgroup,
.c360b2bsnr menu,
.c360b2bsnr nav,
.c360b2bsnr output,
.c360b2bsnr ruby,
.c360b2bsnr section,
.c360b2bsnr summary,
.c360b2bsnr time,
.c360b2bsnr mark,
.c360b2bsnr audio,
.c360b2bsnr video {
  margin: 0;
  padding: 0;
  /* border: 1; */
  /* font-size: 100%; */
  /* font: inherit; */
  vertical-align: baseline;
}

.c360b2bsnr div {
  margin: 0;
  padding: 0;
  border: 2;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

.c360b2bsnr article,
.c360b2bsnr aside,
.c360b2bsnr details,
.c360b2bsnr figcaption,
.c360b2bsnr figure,
.c360b2bsnr footer,
.c360b2bsnr header,
.c360b2bsnr hgroup,
.c360b2bsnr menu,
.c360b2bsnr nav,
.c360b2bsnr section {
  display: block;
}

.c360b2bsnr body {
  line-height: 1;
}

.c360b2bsnr ol,
.c360b2bsnr ul {
  list-style: none;
}

.c360b2bsnr blockquote,
.c360b2bsnr q {
  quotes: none;
}

.c360b2bsnr blockquote:before,
.c360b2bsnr blockquote:after,
.c360b2bsnr q:before,
.c360b2bsnr q:after {
  content: "";
  content: none;
}

.c360b2bsnr table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/2A0298_6_0.eot");
  src: url("./fonts/2A0298_6_0.eot?#iefix") format("embedded-opentype"), url("./fonts/2A0298_6_0.woff") format("woff"),
    url("./fonts/2A0298_6_0.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/2A0298_7_0.eot");
  src: url("./fonts/2A0298_7_0.eot?#iefix") format("embedded-opentype"), url("./fonts/2A0298_7_0.woff") format("woff"),
    url("./fonts/2A0298_7_0.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Proxima Nova";
  src: url("./fonts/2A0298_8_0.eot");
  src: url("./fonts/2A0298_8_0.eot?#iefix") format("embedded-opentype"), url("./fonts/2A0298_8_0.woff") format("woff"),
    url("./fonts/2A0298_8_0.ttf") format("truetype");
  font-weight: 700;
}

.c360b2bsnr html,
.c360b2bsnr body {
  background: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.c360b2bsnr body:not(.focus--mouse) *:focus {
  box-shadow: none;
}

.c360b2bsnr *,
.c360b2bsnr *:after,
.c360b2bsnr *:before {
  box-sizing: border-box;
  outline: none;
}

/* .c360b2bsnr h1, */
.c360b2bsnr h2,
.c360b2bsnr h3,
.c360b2bsnr h4,
.c360b2bsnr h5,
.c360b2bsnr h6,
.c360b2bsnr p,
.c360b2bsnr input,
.c360b2bsnr textarea,
.c360b2bsnr select {
  color: #171719;
  font-family: "Proxima Nova";
  font-weight: normal;
}

.c360b2bsnr h2 {
  font-size: 18px;
}

.c360b2bsnr h3 {
  font-size: 16px;
  font-weight: 600;
}

.c360b2bsnr h4 {
  font-size: 14px;
}

.c360b2bsnr h5 {
  font-size: 13px;
}

.c360b2bsnr h6 {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  font-weight: 600;
}

.c360b2bsnr p,
.c360b2bsnr input,
.c360b2bsnr textarea {
  font-size: 14px;
  line-height: 1.5;
  color: #666666;
}

.c360b2bsnr a,
.c360b2bsnr a:hover {
  color: #cc0000;
  text-decoration: none;
}

.c360b2bsnr img {
  display: block;
  max-width: 100%;
}

.c360b2bsnr button {
  background: none;
  border: none;
  cursor: pointer;
}

.c360b2bsnr textarea,
.c360b2bsnr input {
  border: none;
  padding: 0px;
  background-color: rgba(255, 255, 255, 0);
  min-width: 0px;
  width: 100%;
  resize: none;
  font-size: 12px;
}

.c360b2bsnr label,
.c360b2bsnr small {
  color: #171719;
  font-family: "Proxima Nova";
  font-weight: normal;
  color: #666666;
  font-size: 12px;
}

.c360b2bsnr b,
.c360b2bsnr strong {
  font-weight: 600;
  color: #171719;
}

.c360b2bsnr .CardButton__CardButton_smcal {
  /* width: 75px;
    height: 68px;
    padding: 10px; */
  margin: 1px 1px 10px 0px; /*top right bottom left */
  border: 0px solid #d6dbe2;
  background: #ffffff;
  /* width: 50%; */
  height: 40%;
  /* border-radius: 4px; */
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: left;
  justify-content: center;
  text-align: center;
  /*cursor: pointer;*/
}

.c360b2bsnr .custom_overlay .CardButton__CardButton_sm input[type="radio"] {
  margin: 0;
  padding: 0;
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}

.c360b2bsnr .custom_overlay .CardButton__CardButton_sm label {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  /* cursor: pointer; */
}

.c360b2bsnr .custom_overlay .CardButton__CardButton_sm [type="radio"]:checked + label {
  box-shadow: 0 0 0 1px #cc0000;
  border: 1px solid #cc0000;
}

.c360b2bsnr .custom_overlay .CardButton__CardButton_sm {
  /* width: 50px;
    height: 45.38px; */
  position: relative;
}

.c360b2bsnr .CardButton__CardButton_sm {
  /* width: 75px;
    height: 68px;
    padding: 10px; */
  margin: 10px 2px 12px 0px; /*top right bottom left */
  border: 2px solid #d6dbe2;
  background: #ffffff;
  /* border-radius: 4px; */
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: left;
  justify-content: center;
  text-align: center;
  /*cursor: pointer;*/
}

.c360b2bsnr .CardButton__Group_sm > .CardButton__inner_sm {
  margin-bottom: -8px;
  margin-top: -24px;
  display: flex;
  flex-wrap: wrap;
}

.c360b2bsnr .CardButton__Group_sm > .CardButton__inner_sm > * {
  margin-bottom: 8px;
}

.c360b2bsnr .CardButton__Group_sm > .CardButton__inner_sm > *:last-child {
  margin-right: 0px;
}

@media only screen and (max-width: 768px) {
  .c360b2bsnr .CardButton__Group_sm > .CardButton__inner_sm {
    justify-content: left;
  }

  .c360b2bsnr .CardButton__Group_sm > .CardButton__inner_sm > *:nth-child(even) {
    margin-right: 3px;
  }

  .c360b2bsnr .Popup__card {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .c360b2bsnr .Input__Input {
    padding: 0 10px;
  }

  .c360b2bsnr .ui_panelgrid .ui_panelgrid-even .ui_panelgrid-cell:last-child {
    padding: 4px 0;
  }

  .c360b2bsnr tr.ui_widget-content.ui_panelgrid-odd > td:nth-child(2) > label,
  .c360b2bsnr .ui_timer {
    width: auto;
  }

  /* .c360b2bsnr .Label__main {
  } */

  .c360b2bsnr .Col__Col.Col__padding_sm:last-child .Label__group {
    padding-left: 0px;
  }
}

@media only screen and (max-width: 400px) {
  .c360b2bsnr .Col__Col.Col__sm_12.Col__md_6v {
    display: initial;
    float: table-row;
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 5px;
  }

  .c360b2bsnr .Col__Col.Col__marginTop {
    margin-top: 20px;
  }

  .c360b2bsnr .Col__Col.Col__sm_12.Col__md_5v {
    display: initial;
    float: table-row;
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 5px;
  }

  .c360b2bsnr .Col__Col.Col__sm_12.Col__md_8 {
    display: initial;
    float: table-row;
    flex-basis: 100%;
    max-width: 100%;
    margin-bottom: 5px;
  }

  .c360b2bsnr .Card__Card___37_IE {
    height: inherit;
  }

  .c360b2bsnr .Card__Card___37_IE:not(.Card__size_lg___-6UrJ) .Card__Image___frdcM {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    min-width: 32px;
  }
}

/* .c360b2bsnr .Col__Col.Col__padding_sm:last-child .Label__group {
  padding-left: 15px;
} */

.c360b2bsnr .Col__Col.Col__padding_sm .Label__group {
  width: 100%;
  padding-left: 0px;
}

.c360b2bsnr .Col__Col.Col__padding_sm {
  padding: 0px 0px;
}

.c360b2bsnr .Col__Col.Col__padding_sm_10Left {
  padding-left: 15px;
}

.c360b2bsnr .Col__Col.top_level_submitsm {
  display: -webkit-flex;
  /* Safari */
  -webkit-flex-direction: center;
  /* Safari 6.1+ */
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0px auto;
  align-items: center;
  align-self: center;
}

.c360b2bsnr .Col__Col.Col__md_6 {
  display: initial;
  flex-basis: 50%;
  max-width: 50%;
  vertical-align: center;
}

.c360b2bsnr .Col__Col.Col__md_1 {
  display: initial;
  flex-basis: 8.33333333%;
  max-width: 8.33333333%;
}

.c360b2bsnr .Col__Col.Col__md_1v {
  display: initial;
  flex-basis: 2.33333333%;
  max-width: 2.33333333%;
}

.c360b2bsnr .Col__Col.Col__md_2 {
  display: initial;
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
}

.c360b2bsnr .Col__Col.Col__md_3 {
  display: initial;
  flex-basis: 25%;
  max-width: 25%;
}

.c360b2bsnr .Col__Col.Col__md_4 {
  display: initial;
  flex-basis: 33.33333333%;
  max-width: 33.33333333%;
}

.c360b2bsnr .Col__Col.Col__md_5 {
  display: initial;
  flex-basis: 41.66666667%;
  max-width: 41.66666667%;
}

.c360b2bsnr .Col__Col.Col__md_5v {
  display: initial;
  flex-basis: 47.66666667%;
  max-width: 47.66666667%;
}

.c360b2bsnr .Col__Col.Col__md_6v {
  display: initial;
  flex-basis: 50%;
  max-width: 50%;
}

.c360b2bsnr .Col__Col.Col__md_7 {
  display: initial;
  flex-basis: 58.33333333%;
  max-width: 58.33333333%;
}

.c360b2bsnr .Col__Col.Col__md_8 {
  display: initial;
  flex-basis: 66.66666667%;
  max-width: 66.66666667%;
}

.c360b2bsnr .Col__Col.Col__md_9 {
  display: initial;
  flex-basis: 75%;
  max-width: 75%;
}
.c360b2bsnr .custom_overlay {
  display: flex;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(102, 102, 102, 0.9);*/
  overflow: auto;
  /*padding: 24px;*/
  overflow: hidden;
}

.c360b2bsnr .custom_overlay .Popup__Section {
  padding-bottom: 0px;
  padding-top: 0px;
  box-shadow: none;
}

.c360b2bsnr .custom_overlay .Form__Section {
  border: none;
}

.c360b2bsnr .custom_overlay .CardButton__CardButton input[type="radio"] {
  margin: 0;
  padding: 0;
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}

.c360b2bsnr .custom_overlay .CardButton__CardButton label {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  /* cursor: pointer; */
}

.c360b2bsnr .custom_overlay .CardButton__CardButton [type="radio"]:checked + label {
  border: 1px solid #e22128;
}

.c360b2bsnr .custom_overlay .CardButton__CardButton [type="radio"]:checked + label:after {
  content: "";
  border: 10px solid transparent;
  border-left: 10px solid #e22128;
  position: absolute;
  top: -10px;
  right: -10px;
  transform: rotate(-45deg);
}

.c360b2bsnr .custom_overlay .CardButton__CardButton {
  width: 100px;
  height: 90.67px;
  position: relative;
}
.c360b2bsnr .custom_overlay select {
  border: none;
}

.c360b2bsnr .custom_overlay select.Input__Input option {
  margin: 40px;
  background: #fff;
  color: #000;
}

.c360b2bsnr .custom_overlay .Input__Input {
  height: 50px;
  border-bottom: solid 2px #000000;
  background: #f8f8f8;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.c360b2bsnr .disable_datepicket {
  color: gray !important;
  opacity: 0.4;
  cursor: default;
}

.c360b2bsnr .enable_datepicket {
  color: black;
  opacity: 1;
  cursor: default;
}

.c360b2bsnr .Form__Form:not(.Form__plain) > * {
  margin-bottom: 16px;
}

.c360b2bsnr .Form__Form:not(.Form__plain) > *:last-child {
  margin-bottom: 0px;
}

.c360b2bsnr h1 {
  border: 0;
  border-color: #eed3d7;
  color: #d8000c;
  display: flex;
  font-family: "'Times New Roman', Times";
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-decoration: none solid rgb(226, 33, 40);
  text-align: left;
  word-spacing: 0px;
  /* font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: bold; */
  padding: 10px 2px 2px 10px !important;
  padding-block-end: 10px !important;
  white-space: "break-spaces";
  vertical-align: middle;
}

.c360b2bsnr .Input__Input.red {
  border-color: #cc0000;
}

.c360b2bsnr .Input__Input input,
select.Input__Input {
  color: #171719;
  padding-left: 20px;
  margin-right: 20px;
  font-size: 16px;
}

.c360b2bsnr .Input__Input:focus-within,
.c360b2bsnr select.Input__Input:focus-within {
  border-image: linear-gradient(270deg, #4d80e6 0%, #e12128 100%);
  border-image-slice: 1;
}

.c360b2bsnr .Input__Input input:focus,
.c360b2bsnr select.Input__Input:focus {
  box-shadow: none;
}

.c360b2bsnr .select.Input__Input::-ms-expand {
  display: none;
}

.c360b2bsnr .Label__field {
  border: 0;
  color: #171719;
  font-family: "Proxima Nova";
  font-size: 18px;
  font-weight: 500;
  font: inherit;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

.c360b2bsnr .Label__group > *:first-child {
  flex: 1;
  width: 100%;
}

.c360b2bsnr .Label__group > *:last-child {
  margin-right: 0px;
}

.c360b2bsnr .Label__group > * {
  margin-right: 12px;
}

.c360b2bsnr .Label__group .wrapper {
  position: unset;
}

.c360b2bsnr .Label__main {
  font-weight: 600;
  margin-bottom: 8px;
}

.c360b2bsnr .Link__Link {
  color: #171719;
  font-family: "Proxima Nova";
  font-weight: normal;
  color: #666666;
  font-size: 12px;
  display: block;
  transition: 0.2s color;
  /* cursor: pointer; */
}

.c360b2bsnr .Popup__card {
  width: 100%;
  background-color: #ffffff;
  max-height: 100%;
}

.c360b2bsnr .Sample__container__content {
  display: flex;
  max-width: 100%;
  /* min-width: 400px; */
  flex-basis: 420px;
  flex-direction: column;
  flex-grow: 100;
  align-items: stretch;
  padding-top: 1em;
  padding-left: 1em;
}

.c360b2bsnr .Sample__container__content .react_calendar {
  margin: 0 auto;
}

.c360b2bsnr .react_datepicker_popper {
  border: 1px;
}
.c360b2bsnr .Row__Row.Row__padding_sm:last-child {
  margin-bottom: 0px;
}

.c360b2bsnr .Row__Row.Row__padding_sm {
  margin: 5px 0px 25px 0px; /*top right bottom left */
}

.c360b2bsnr .Row__Row.Row__padding_sm_label {
  margin: 0px 0px 5px 0px; /*top right bottom left */
}

.c360b2bsnr .Row__Row.Row__padding_smb:first-child {
  margin-left: 10px;
}

.c360b2bsnr .Row__Row.Row__padding_smb:last-child {
  margin-bottom: 0px;
}

.c360b2bsnr .Row__Row.Row__padding_smb {
  margin: 25px 0px; /*top-bottom right-left */
  justify-content: bottom;
}

.c360b2bsnr .Row__Row_Left.Row__padding_smcal {
  margin: 0px 0px;
  margin-bottom: 25px;
}

.c360b2bsnr .Row__Row.Row__padding_smcal:first-child {
  margin-left: 0px;
}

.c360b2bsnr .Row__Row.Row__padding_smcal:last-child {
  margin-bottom: 25px 0px;
}

.c360b2bsnr .Row__Row.Row__padding_smcal {
  margin: 25px 0px; /*top-bottom right-left */
  justify-content: bottom;
}

.c360b2bsnr .Row__Row {
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  justify-content: center;
}

.c360b2bsnr .Row__Row__Left {
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  justify-content: left;
}

.c360b2bsnr .Row__Row_Left.Row__padding_sm {
  margin: 0px 0px;
}

.c360b2bsnr .Row__Row_Left.Row__padding_smref:last-child {
  margin-bottom: -17px;
}

.c360b2bsnr .Row__Row_Left.Row__padding_smref {
  margin: 0px 0px;
}

.c360b2bsnr .Row__Row_Left {
  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  justify-content: left;
  flex-direction: column;
}

.c360b2bsnr .Search__SearchContainer {
  position: relative;
}

.c360b2bsnr .Search__SearchInput_focus {
  border-bottom: linear-gradient(270deg, #4d80e6 0%, #e12128 100%);
  border-image: linear-gradient(270deg, #4d80e6 0%, #e12128 100%);
  border-image-slice: 1;
}

.c360b2bsnr .top_level {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px auto;
  justify-content: left;
}

.c360b2bsnr .Search__SearchInput:focus {
  border-bottom: linear-gradient(270deg, #4d80e6 0%, #e12128 100%);
  border-image: linear-gradient(270deg, #4d80e6 0%, #e12128 100%);
  border-image-slice: 1;
}
