.instlpaypoc-grid-container {
  display: grid;
  grid-template-columns: 160px 75px 75px 75px 75px 30px;
  /* background-color: #faf0e6; */
  column-gap: 10px;
  row-gap: 5px;
}
.instlpaypoc-span2 {
  grid-column: span 2;
}
.instlpaypoc-span5 {
  grid-column: span 5;
}
.instlpaypoc-span3by2 {
  grid-column: span 3;
  grid-row: span 2;
}

.instlpaypoc-grid-container > div {
  /* background-color: rgba(255, 255, 255, 0.8); */
  text-align: left;
}

.instlpaypoc-image {
  padding: 0px, 3px 0px 0px;
  border-radius: 0px, 2px 0px 0px;
  text-align: center;
}

.instlpaypocDisabled {
  pointer-events: none;
  opacity: 0.3;
}

.instlpaypocTextInput {
  font-size: 8pt;
  font-family: '"Arial", sans-serif';
}

.instlpaypocFieldError {
  background-color: #ff6060 !important;
}
.instlpaypocFieldError:-webkit-autofill {
  background-color: #ff6060 !important;
}

.instlpaypocMarginLeft {
  margin-left: 10px;
}

.instlpaypocMarginLeft5 {
  margin-left: 5px;
}

.instlpaypocMarginLeft20 {
  margin-left: 20px;
}

.flex-microform {
  height: 20px;
  background: #ffffff;
  -webkit-transition: background 200ms;
  transition: background 200ms;
}
#securityCode-container.flex-microform {
  background: #ffffff;
}

.flex-microform-focused {
  background: lightyellow;
}

.flex-microform-valid {
  background: lightyellow;
}
