.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker__input-container {
  width: 100%;
  position: relative;
  padding: 0.5rem;
  box-sizing: content-box;
  display: inline-block;
  display: flex;
  flex-direction: row-reverse;
}

.react-datepicker__calendar-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.react-datepicker {
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 2px solid #636161;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  line-height: initial;
}
