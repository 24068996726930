.grid-container {
  display: grid;
  grid-template-columns: 200px 170px 150px 155px 75px 50px;
  gap: 5px;
}
.grid-container > div {
  /* background-color: rgba(255, 255, 255, 0.8); */
  text-align: right;
}

.textInput {
  font-size: 8pt;
  margin: 0px;
  padding: 0px;
  border: solid;
  border-width: thin;
  font-family: "Arial", sans-serif;
}

.inputFocused {
  border: solid;
  border-width: thin;
  width: 165px;
}
.inputNotFocused {
  margin: 1px;
}

.fieldError {
  background-color: #ff6060 !important;
}
.fieldError:-webkit-autofill {
  background-color: #ff6060 !important;
}
